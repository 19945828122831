<template>
  <UTooltip v-if="tooltip" :text="tooltip">
    <VsButton :active="active"
              :block="block"
              :class="className"
              :color="color || 'primary'"
              :disabled="disabled"
              :flat="flat"
              :loading="loading"
              class="button__primary"
    >
    <span class="gap-1 flex items-center">
      <Icon v-if="icon && !icon.includes('isax') && !iconAfter" :name="icon"></Icon>
      <i v-else-if="icon && !iconAfter" :class="icon" class="isax" style="font-size: 1rem"></i>
      <p v-if="text">{{ text }}</p>
      <slot v-else />
      <UIcon v-if="link === ''" name="i-heroicons-arrow-top-right-on-square" />
      <Icon v-if="icon && !icon.includes('isax') && iconAfter" :name="icon"></Icon>
      <i v-else-if="icon && iconAfter" :class="icon" class="isax" style="font-size: 1rem"></i>
    </span>
    </VsButton>
  </UTooltip>
  <VsButton v-else
            :active="active"
            :block="block"
            :class="className"
            :color="color || 'primary'"
            :disabled="disabled"
            :flat="flat"
            :loading="loading"
            class="button__primary"
  >
    <span class="gap-1 flex items-center">
      <Icon v-if="icon && !icon.includes('isax') && !iconAfter" :name="icon"></Icon>
      <i v-else-if="icon && !iconAfter" :class="icon" class="isax" style="font-size: 1rem"></i>
      <p v-if="text">{{ text }}</p>
      <slot v-else />
      <UIcon v-if="link === ''" name="i-heroicons-arrow-top-right-on-square" />
      <Icon v-if="icon && !icon.includes('isax') && iconAfter" :name="icon"></Icon>
      <i v-else-if="icon && iconAfter" :class="icon" class="isax" style="font-size: 1rem"></i>
    </span>
  </VsButton>
</template>
<script lang="ts" setup>
import { VsButton } from "vuesax-ts";

defineProps([
  "active",
  "block",
  "className",
  "color",
  "disabled",
  "flat",
  "icon",
  "iconAfter",
  "link",
  "loading",
  "text",
  "tooltip"
]);
</script>


<script lang="ts">
export default {
  name: "Button"
};
</script>
